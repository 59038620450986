import { Properties } from './properties.model';

export enum DataSourceType {
    Sql = 'sql',
    Reference = 'reference',
}

export enum AggregateType {
    Value = 'value',
    Sum = 'sum',
    Count = 'count',
    Avg = 'avg',
    Max = 'max',
    Min = 'min',
}

export enum GroupType {
    Value = 'value',
    Date = 'date',
    Hour = 'hour',
    Minute = 'minute',
    Year = 'year',
    Month = 'month',
    Function = 'function',
}

export enum ColumnType {
    String = 'string',
    Number = 'number',
    Datetime = 'datetime',
    Array = 'array',
    Object = 'object',
    Boolean = 'boolean',
}

export enum Condition {
    '<' = '<',
    '>' = '>',
    '<=' = '<=',
    '>=' = '>=',
    '=' = '=',
    '<>' = '<>',
    'AND' = 'AND',
    'OR' = 'OR',
}

export interface ColumnTypeMap {
    [key: string]: ColumnType;
}

export interface ColumnData {
    name: string;
    type: ColumnType;
}

export interface DataSourceMeta {
    error?: string;
    text?: string;
    location?: {
        start: {offset: number; line: number; column: number; };
        end: {offset: number; line: number; column: number; };
    };
    collection?: string;
    columns: ColumnData[];
    keyFields?: string[];
    pipeline?: any[];
    tables?: Array<{ name: string; columns: string[]; }>;
    variables: string[];
}

export interface DataSourceValue {
    table?: string;
    column?: string;
    fn?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    eval?: Function;
    value?: any;
    variable?: string;

    group?: GroupType;
    aggregate?: AggregateType;
    sort?: boolean;
    alias?: string;
}

export interface DataSourceCondition {
    values?: Array<DataSourceValue>;
    conditions?: Array<DataSourceCondition>;
    condition?: Condition;
    alias?: string;
}

export interface DataSourceTable {
    collection?: string;
    query?: DataSourceQuery;
    union?: DataSourceTable[];
    sql?: string;
    variables?: Array<DataSourceVariable>;
    refresh?: number;
    alias?: string;
    optional?: boolean;
    conditions?: Array<DataSourceCondition>;
    source?: {
        dataSourceId?: string;
        dashboardId?: string;
    };
}

export interface DataSourceQuery {
    from?: Array<DataSourceTable>;
    select?: Array<DataSourceValue>;
    where?: Array<DataSourceCondition>;
    limit?: number;
}

export interface DataSource {
    id?: string;
    tags?: string[];
    name?: string;
    type?: DataSourceType;
    folderId?: string;

    // Reference type
    query?: DataSourceQuery;

    // SQL type
    sql?: string;
    refresh?: number;
    with?: Array<{
        dataSourceId?: string;
        alias?: string;
    }>;

    variables?: DataSourceVariable[];
    properties?: Properties;
    meta?: DataSourceMeta;
}

export interface DataSourceVariable {
    name?: string;
    value?: any;
    reference?: string;
}
